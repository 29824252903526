/* App.css */

html, body {
  height: 100%;
  margin: 0;
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #333;
}

.App {
  display : flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  color : black;
}

header {
  background-color: #333;
  padding: 10px 0;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .logo {
  height: 60px;
  margin-left: 20px;
  border-radius: 10%;
  background-color: #444; /* Slightly lighter color than the background */
  padding: 10px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

nav ul li a:hover {
  text-decoration: underline;
}

.header-logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.header-icon {
  color: white;
  font-size: 24px;
  margin-right: 10px;
  background-color: #444; /* Slightly lighter color than the background */
  padding: 10px;
  border-radius: 50%;
  width: 50px; /* Ensure width and height are equal */
  height: 50px; /* Ensure width and height are equal */
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-name {
  color: white;
  font-size: 18px;
}

main {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  color: black;
}

h2 {
  color: #333;
}

p {
  color: #666;
  line-height: 1.6;
}

footer {
  background-color: #8d8c8c;
  color: white;
  padding: 10px 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer nav ul {
  list-style: none;
  padding: 0;
}


footer nav ul li {
  display: inline;
  margin: 0 10px;  
}

footer nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 0.8em;
}

footer nav ul li a:hover {
  text-decoration: underline;
}