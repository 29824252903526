.wellness-tips-panel {
  position: fixed;
  top: 80px; /* Adjust based on your header height */
  left: 0;
  width: 250px;
  background-color: #333;
  color: white;
  border-right: 1px solid #ccc;
  padding: 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: calc(100% - 80px); /* Adjust based on your header height */
  overflow-y: auto;
}

.wellness-tips-panel h3 {
  margin-top: 0;
  font-size: 18px;
  color: white;
}

.wellness-tips-panel p {
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
}

.wellness-tips-panel a {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.wellness-tips-panel a:hover {
  text-decoration: underline;
}