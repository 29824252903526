
.product-section {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  background-color: #333;
  color: white;
}

.product-section h2 {
  color: white;
}

.product-section p {
  color: #ccc;
  line-height: 1.6;
}
.product-section span {
  color: #ccc;
  line-height: 1.6;
}

.product-section a {
  color: #bcdbfc; /* Ensure link color is readable on grey background */
  text-decoration: none;
}

.product-section a:hover {
  text-decoration: underline;
}
.pricing {  
  justify-content: space-around;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 20px;
}