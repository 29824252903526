/* CompanySection.css */

.faq-section {
  max-width: 1200px;
  min-width : 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #333; /* Light background color for the section */
  width : 80%;
  box-sizing : border-box;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensure the container is left-aligned */
  width: 100%; /* Ensure the container takes up the full width of the section */
}

.faq-item {
  margin-bottom: 10px;
  width : 100%;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #7d7c7c; /* Lighter background color for the question bar */
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.faq-question h2 {
  margin: 0;
  font-size: 18px;
  flex-grow: 1;
}

.faq-answer {
  padding: 10px;
  background-color: #5c5c5c; /* White background for the answer */
  border-radius: 5px;
  margin-top: 5px;
  width : 100%;
}

.chevron {
  font-size: 18px;
}

.faq-section h2 {
  color: white;
  width: 100%;
}

.faq-section p {
  color: #ccc;
  line-height: 1.6;
}

.faq-section a {
  color: #bcdbfc; /* Ensure link color is readable on grey background */
  text-decoration: none;
}

.faq-section a:hover {
  text-decoration: underline;
}