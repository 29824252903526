/* CompanySection.css */

.documentation-section {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  background-color: #333;
  color: white;
}

.documentation-section h2 {
  color: white;
}

.documentation-section p {
  color: #ccc;
  line-height: 1.6;
}

.container {
  display: flex;
  padding: 20px;
}

.index {
  width: 250px;
  margin-right: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
}

.index-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.list {
  list-style: none;
  padding: 0;
}

.list-item {
  margin-bottom: 10px;
}

.button {
  background: none;
  border: none;
  color: #007bff;
  text-align: left;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
}

.button:hover {
  text-decoration: underline;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  overflow-y: auto; /* Ensure the content container always has a scrollbar */
  min-height: 400px; /* Set a minimum height to prevent layout shift */
}

/* CSS Reset for consistent styling */
.content * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Additional styling for content */
.content p {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.content img {
  max-width: 100%;
  height: auto;
}